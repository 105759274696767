<template>
  <button type="button"
          @click="generateNews()"
          :disabled="(isArchived && game.mustGenerateNews) || game.hasNews"
          class="btn btn-sm" :class="{'btn-success': game.mustGenerateNews || game.hasNews || loading, 'btn-info': !(game.mustGenerateNews || game.hasNews || loading)}"><i v-if="!loading" class="fa fa-newspaper-o"></i><i v-if="loading"
                                                                                                                                                                                       class="fa fa-cog fa-spin"></i>
  </button>
</template>

<script>
import GameService from '@/services/game.service'

export default {
  props: {
    game: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    generateNews () {
      this.loading = true
      GameService.generateAiNews(this.game.schedule.uuid, this.game.uuid).then((res) => {
        // eslint-disable-next-line vue/no-mutating-props
        this.game.mustGenerateNews = res.data
        this.loading = false
      })
    }
  },
  computed: {
    isArchived () {
      return ['ARCHIVED'].includes(this.game.status)
    }
  }
}

</script>
