import ApiService from './api.service'

const DashboardService = {
  index (currentTimeline = 'default') {
    return ApiService.get('api/v1/admin/dashboard', {
      params: {
        currentTimeline
      }
    })
  },
  exportScheduledGamesCsv (data) {
    return ApiService.postWithoutLoading('api/v1/admin/dashboard/export-scheduled-games-csv', data)
  },
  finishedGames () {
    return ApiService.get('api/v1/admin/user/finished-games')
  },
  generateCode (gameUuid) {
    return ApiService.postWithoutLoading(`api/v1/admin/dashboard/generate-code/${gameUuid}`)
  },
  swapGameTimeAndVenue (game1Uuid, game2Uuid) {
    console.log('game1Uuid', game1Uuid)
    console.log('game2Uuid', game2Uuid)
    return ApiService.postWithoutLoading('api/v1/admin/dashboard/swap-games', {
      game1Uuid,
      game2Uuid
    })
  },
  publishTimeline (timelineId) {
    return ApiService.postWithoutLoading(`api/v1/admin/dashboard/timeline/${timelineId}/publish`)
  }
}

export default DashboardService
