<template>
  <div class="about">
    <page-header :title="$t('settings.name')">

    </page-header>
    <page-content>
      <ntm-block :title="`TV CODE`" :init-collapse="false" :collapsable="false">
        <template v-slot:options>
          <span class="mb-0 font-size-h4 font-weight-bold">{{ model.tvCode?.replace(/(?=(?:.{3})*$)/g, ' ')}}</span>
          <button @click="generateUserTvCode()" class="btn btn-sm" :class="{'btn-warning': model.tvCode, 'btn-success': !model.tvCode}">
            <i class="fa fa-undo"></i>
          </button>
        </template>
      </ntm-block>
      <ntm-block title="Logo" :init-collapse="false">
        <ntm-crop v-if="model.uuid" entity-type="settings" :aspect-ratio="2/1" :max-width="500" :max-height="250"
                  :entity-uuid="model.uuid" conversion="lg" collection="logo"></ntm-crop>
      </ntm-block>

      <ntm-block :title="$tc('crest')" :init-collapse="false">
        <ntm-crop v-if="model.uuid" entity-type="settings" :entity-uuid="model.uuid" conversion="lg" collection="crest"
                  :circle="true"></ntm-crop>
      </ntm-block>

      <ntm-block :title="$tc('news.defaultCover')" :init-collapse="false">
        <ntm-crop v-if="model.uuid" :aspect-ratio="16/10" entity-type="settings" conversion="lg" :max-width="1280"
                  :max-height="800" :entity-uuid="model.uuid" collection="news"></ntm-crop>
      </ntm-block>

      <ntm-block :title="$tc('livestream.logo')" :init-collapse="false">
        <ntm-crop v-if="model.uuid" entity-type="settings" :max-width="500" :max-height="500" :entity-uuid="model.uuid"
                  collection="broadcast"></ntm-crop>
      </ntm-block>

      <form @submit.prevent="onSubmit()">
        <ntm-block>
          <formly-form :form="form" :model="model" :fields="fields"></formly-form>
          <button class="btn btn-success push-15">{{ $t('save') }}</button>
        </ntm-block>
      </form>
    </page-content>
  </div>
</template>

<script>
import SettingsService from '../../services/settings.service'
import fields from './formDefinition'
import UserService from '@/services/user.service'
import store from '@/store'

export default {
  data () {
    return {
      model: {},
      form: {},
      fields: fields
    }
  },
  beforeRouteEnter (to, from, next) {
    SettingsService.index().then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  mounted () {
    const availableLanguages = store.state.availableLanguages.map(lang => lang.id)

    const field = this.fields.find(field => field.key === 'lang')

    field.options = availableLanguages

    this.fields[6].templateOptions.uuid = this.model.uuid
    this.fields[6].templateOptions.assetType = 'settings'

    const timezoneSelect = this.fields.find((field) => field.key === 'timezone')

    timezoneSelect.options = this.model.timezones
  },
  methods: {
    generateUserTvCode () {
      SettingsService.generateUserTvCode().then((res) => {
        console.log(res)
        this.model.tvCode = res.data.toString()
      })
    },
    setData (data) {
      this.model = data
    },
    onSubmit () {
      SettingsService.store(this.model).then(async () => {
        await UserService.getUser().then((response) => {
          store.commit('currentUser', response.data)
        })
        this.$store.commit('loaded')
      }).catch((err) => {
        this.form.$errors = err.response.data.errors
      })
    }
  }
}
</script>
