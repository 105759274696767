<template>
  <button @click="clipboard" class="btn btn-sm btn-info"><i v-if="!loading" :class="icon"></i> <i v-if="loading" class="fa fa-cog fa-spin"></i></button>
</template>

<script>
import GameService from '@/services/game.service'

export default {
  props: {
    game: {
      type: Object,
      default: () => {}
    },
    icon: {
      type: String,
      default: 'fa fa-image'
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    clipboard () {
      this.loading = true
      GameService.generateImage(this.game.schedule.uuid, this.game.uuid).then(response => {
        this.$copyText(response.data.imageUrl).then(() => {
          console.log(response.data?.imageUrl)
          this.$toast.fire({
            title: 'Copied to clipboard',
            icon: 'success'
          })
        })
      }).finally(() => {
        this.loading = false
      })
    }
  }
}

</script>
