import ApiService from './api.service'

const GameService = {
  show (scheduleUuid, gameUuid) {
    return ApiService.get('api/v1/admin/schedules/' + scheduleUuid + '/games/' + gameUuid)
  },

  generateImage (scheduleUuid, gameUuid) {
    return ApiService.postWithoutLoading('api/v1/admin/schedules/' + scheduleUuid + '/games/' + gameUuid + '/generate-image')
  },

  clone (scheduleUuid, gameUuid, cloneGameUuid) {
    return ApiService.post('api/v1/admin/schedules/' + scheduleUuid + '/games/' + gameUuid + '/clone/' + cloneGameUuid)
  },

  update (scheduleUuid, gameUuid, data) {
    return ApiService.put('api/v1/admin/schedules/' + scheduleUuid + '/games/' + gameUuid, data)
  },

  scheduleGame (scheduleUuid, gameUuid, data) {
    return ApiService.putWithoutLoading('api/v1/admin/schedules/' + scheduleUuid + '/games/' + gameUuid + '/schedule-game', data)
  },

  cancelScheduledGame (scheduleUuid, gameUuid, data) {
    return ApiService.deleteWithoutLoading('api/v1/admin/schedules/' + scheduleUuid + '/games/' + gameUuid + '/cancel-scheduled-game', data)
  },

  updateStats (scheduleUuid, gameUuid, data) {
    return ApiService.put('api/v1/admin/schedules/' + scheduleUuid + '/games/' + gameUuid + '/stats', data)
  },

  swapPlayerStats (scheduleUuid, gameUuid, data) {
    return ApiService.putWithoutLoading('api/v1/admin/schedules/' + scheduleUuid + '/games/' + gameUuid + '/swap-player-stats', data)
  },

  store (scheduleUuid, data) {
    return ApiService.post('api/v1/admin/schedules/' + scheduleUuid + '/games', data)
  },

  destroy (scheduleUuid, gameUuid) {
    return ApiService.delete('api/v1/admin/schedules/' + scheduleUuid + '/games/' + gameUuid)
  },

  generateAiNews (scheduleUuid, gameUuid) {
    return ApiService.postWithoutLoading('api/v1/admin/schedules/' + scheduleUuid + '/games/' + gameUuid + '/generate-ai-news')
  },

  getPlayerStats (scheduleUuid, gameUuid, teamUuid, playerUuid) {
    return ApiService.get('api/v1/admin/schedules/' + scheduleUuid + '/games/' + gameUuid + '/teams/' + teamUuid + '/players/' + playerUuid)
  },

  updatePlayerStats (scheduleUuid, gameUuid, teamUuid, playerUuid, data) {
    return ApiService.put('api/v1/admin/schedules/' + scheduleUuid + '/games/' + gameUuid + '/teams/' + teamUuid + '/players/' + playerUuid, data)
  },

  deletePlayerStats (scheduleUuid, gameUuid, teamUuid, playerUuid) {
    return ApiService.delete('api/v1/admin/schedules/' + scheduleUuid + '/games/' + gameUuid + '/teams/' + teamUuid + '/players/' + playerUuid)
  },

  editMode (scheduleUuid, gameUuid) {
    return ApiService.putWithoutLoading('api/v1/admin/schedules/' + scheduleUuid + '/games/' + gameUuid + '/editmode')
  },

  archive (scheduleUuid, gameUuid) {
    return ApiService.putWithoutLoading('api/v1/admin/schedules/' + scheduleUuid + '/games/' + gameUuid + '/archive')
  },
  resolveAndArchive (scheduleUuid, gameUuid) {
    return ApiService.putWithoutLoading('api/v1/admin/schedules/' + scheduleUuid + '/games/' + gameUuid + '/resolve-and-archive')
  },

  reset (scheduleUuid, gameUuid) {
    return ApiService.delete('api/v1/admin/schedules/' + scheduleUuid + '/games/' + gameUuid + '/reset')
  }

}

export default GameService
